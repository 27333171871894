module.exports = [
  {
    "name": "Property",
    "expanded": true,
    "filters": [
      {
        "name": "Material ID",
        "params": ["material_ids"],
        "overrides": [
          "elements",
          "formula",
          "chemsys",
          "exclude_elements",
          "nelements_min",
          "nelements_max"
        ],
        "isSearchBarField": true,
        "tooltip": "Find materials with a specific Material ID, e.g. \"mp-10152\"",
        "type": "MATERIALS_INPUT",
        "props": {
          "type": "mpid",
          "periodicTableMode": "none",
          "errorMessage": "Please enter a valid material ID (e.g. mp-394)."
        }
      },
      {
        "name": "Formula",
        "params": ["formula"],
        "overrides": [
          "material_ids",
          "elements",
          "chemsys",
          "exclude_elements",
          "nelements_min",
          "nelements_max"
        ],
        "isSearchBarField": true,
        "tooltip": "Enter a chemical formula to find materials that have this exact formula. Use the \"*\" symbol to specify a wildcard element.",
        "type": "MATERIALS_INPUT",
        "props": {
          "type": "formula",
          "periodicTableMode": "none",
          "errorMessage": "Please enter a valid chemical formula (e.g. CeZn5).",
          "helpItems": [
            {
              "label": "Formula Examples"
            },
            {
              "label": null,
              "examples": ["Li3Fe", "Eu2SiCl2O3", "LiFe*2*", "Si*"]
            }
          ]
        }
      },
      {
        "name": "Space Group Symbol",
        "params": ["spacegroup_symbol"],
        "tooltip": "The standard short symbol for the spacegroup.",
        "type": "SELECT_SPACEGROUP_SYMBOL"
      },
      {
        "name": "Space Group International Number",
        "params": ["spacegroup_number"],
        "tooltip": "The international number for the spacegroup.",
        "type": "SELECT_SPACEGROUP_NUMBER"
      },
      {
        "name": "Band Gap",
        "params": ["band_gap_min", "band_gap_max"],
        "type": "SLIDER",
        "units": "eV",
        "props": {
          "domain": [0, 20],
          "step": 0.1
        }
      },
      {
        "name": "CBM",
        "params": ["cbm_min", "cbm_max"],
        "overrides": ["material_ids", "formula", "chemsys"],
        "type": "SLIDER",
        "props": {
          "domain": [1, 20],
          "step": 0.01
        }
      },
      {
        "name": "VBM",
        "params": ["vbm_min", "vbm_max"],
        "overrides": ["material_ids", "formula", "chemsys"],
        "type": "SLIDER",
        "props": {
          "domain": [1, 20],
          "step": 0.01
        }
      },
      {
        "name": "Formation Energy",
        "params": ["formation_energy_per_atom_min", "formation_energy_per_atom_max"],
        "type": "SLIDER",
        "units": "eV/atom",
        "props": {
          "domain": [-10, 6],
          "step": 0.1
        }
      }
    ]
  }
]
;