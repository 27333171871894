export const spaceGroups = [
  {
    full_symbol: 'P1',
    crystal_system: 'Triclinic',
    point_group: '1',
    int_number: 1,
    symbol: 'P1',
    symbol_unicode: 'P1',
    symbol_latex: 'P1',
    point_group_unicode: '1',
    point_group_latex: '1'
  },
  {
    full_symbol: 'P-1',
    crystal_system: 'Triclinic',
    point_group: '-1',
    int_number: 2,
    symbol: 'P-1',
    symbol_unicode: 'P1\u0305',
    symbol_latex: 'P$\\overline{1}$',
    point_group_unicode: '1\u0305',
    point_group_latex: '$\\overline{1}$'
  },
  {
    full_symbol: 'P121',
    crystal_system: 'Monoclinic',
    point_group: '2',
    int_number: 3,
    symbol: 'P2',
    symbol_unicode: 'P121',
    symbol_latex: 'P121',
    point_group_unicode: '2',
    point_group_latex: '2'
  },
  {
    full_symbol: 'P12_11',
    crystal_system: 'Monoclinic',
    point_group: '2',
    int_number: 4,
    symbol: 'P2_1',
    symbol_unicode: 'P12_11\u0305',
    symbol_latex: 'P12$_{11}$',
    point_group_unicode: '2',
    point_group_latex: '2'
  },
  {
    full_symbol: 'C121',
    crystal_system: 'Monoclinic',
    point_group: '2',
    int_number: 5,
    symbol: 'C2',
    symbol_unicode: 'C121',
    symbol_latex: 'C121',
    point_group_unicode: '2',
    point_group_latex: '2'
  },
  {
    full_symbol: 'P1m1',
    crystal_system: 'Monoclinic',
    point_group: 'm',
    int_number: 6,
    symbol: 'Pm',
    symbol_unicode: 'P1m1',
    symbol_latex: 'P1m1',
    point_group_unicode: 'm',
    point_group_latex: 'm'
  },
  {
    full_symbol: 'P1c1',
    crystal_system: 'Monoclinic',
    point_group: 'm',
    int_number: 7,
    symbol: 'Pc',
    symbol_unicode: 'P1c1',
    symbol_latex: 'P1c1',
    point_group_unicode: 'm',
    point_group_latex: 'm'
  },
  {
    full_symbol: 'C1m1',
    crystal_system: 'Monoclinic',
    point_group: 'm',
    int_number: 8,
    symbol: 'Cm',
    symbol_unicode: 'C1m1',
    symbol_latex: 'C1m1',
    point_group_unicode: 'm',
    point_group_latex: 'm'
  },
  {
    full_symbol: 'C1c1',
    crystal_system: 'Monoclinic',
    point_group: 'm',
    int_number: 9,
    symbol: 'Cc',
    symbol_unicode: 'C1c1',
    symbol_latex: 'C1c1',
    point_group_unicode: 'm',
    point_group_latex: 'm'
  },
  {
    full_symbol: 'P12/m1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 10,
    symbol: 'P2/m',
    symbol_unicode: 'P12/m1',
    symbol_latex: 'P12/m1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'P12_1/m1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 11,
    symbol: 'P2_1/m',
    symbol_unicode: 'P12\u2081/m1',
    symbol_latex: 'P12$_{1}$/m1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'C12/m1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 12,
    symbol: 'C2/m',
    symbol_unicode: 'C12/m1',
    symbol_latex: 'C12/m1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'P12/c1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 13,
    symbol: 'P2/c',
    symbol_unicode: 'P12/c1',
    symbol_latex: 'P12/c1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'P12_1/c1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 14,
    symbol: 'P2_1/c',
    symbol_unicode: 'P12\u2081/c1',
    symbol_latex: 'P12$_{1}$/c1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'C12/c1',
    crystal_system: 'Monoclinic',
    point_group: '2/m',
    int_number: 15,
    symbol: 'C2/c',
    symbol_unicode: 'C12/c1',
    symbol_latex: 'C12/c1',
    point_group_unicode: '2/m',
    point_group_latex: '2/m'
  },
  {
    full_symbol: 'P222',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 16,
    symbol: 'P222',
    symbol_unicode: 'P222',
    symbol_latex: 'P222',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'P222_1',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 17,
    symbol: 'P22_12',
    symbol_unicode: 'P222\u2081',
    symbol_latex: 'P222$_{1}$',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'P2_12_12',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 18,
    symbol: 'P2_122_1',
    symbol_unicode: 'P2_12\u0305_12\u0305',
    symbol_latex: 'P2$_{12}$$_{12}$',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'P2_12_12_1',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 19,
    symbol: 'P2_12_12_1',
    symbol_unicode: 'P2_12\u0305_121\u0305',
    symbol_latex: 'P2$_{12}$$_{121}$',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'C222_1',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 20,
    symbol: 'B22_12',
    symbol_unicode: 'C222\u2081',
    symbol_latex: 'C222$_{1}$',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'C222',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 21,
    symbol: 'B222',
    symbol_unicode: 'C222',
    symbol_latex: 'C222',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'F222',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 22,
    symbol: 'F222',
    symbol_unicode: 'F222',
    symbol_latex: 'F222',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'I222',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 23,
    symbol: 'I222',
    symbol_unicode: 'I222',
    symbol_latex: 'I222',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'I2_12_12_1',
    crystal_system: 'Orthorhombic',
    point_group: '222',
    int_number: 24,
    symbol: 'I2_12_12_1',
    symbol_unicode: 'I2_12\u0305_121\u0305',
    symbol_latex: 'I2$_{12}$$_{121}$',
    point_group_unicode: '222',
    point_group_latex: '222'
  },
  {
    full_symbol: 'Pmm2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 25,
    symbol: 'Pm2m',
    symbol_unicode: 'Pmm2',
    symbol_latex: 'Pmm2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pmc2_1',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 26,
    symbol: 'Pm2_1b',
    symbol_unicode: 'Pmc2\u2081',
    symbol_latex: 'Pmc2$_{1}$',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pcc2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 27,
    symbol: 'Pb2b',
    symbol_unicode: 'Pcc2',
    symbol_latex: 'Pcc2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pma2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 28,
    symbol: 'Pm2a',
    symbol_unicode: 'Pma2',
    symbol_latex: 'Pma2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pca2_1',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 29,
    symbol: 'Pb2_1a',
    symbol_unicode: 'Pca2\u2081',
    symbol_latex: 'Pca2$_{1}$',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pnc2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 30,
    symbol: 'Pn2b',
    symbol_unicode: 'Pnc2',
    symbol_latex: 'Pnc2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pmn2_1',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 31,
    symbol: 'Pm2_1n',
    symbol_unicode: 'Pmn2\u2081',
    symbol_latex: 'Pmn2$_{1}$',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pba2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 32,
    symbol: 'Pc2a',
    symbol_unicode: 'Pba2',
    symbol_latex: 'Pba2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pna2_1',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 33,
    symbol: 'Pn2_1a',
    symbol_unicode: 'Pna2\u2081',
    symbol_latex: 'Pna2$_{1}$',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Pnn2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 34,
    symbol: 'Pn2n',
    symbol_unicode: 'Pnn2',
    symbol_latex: 'Pnn2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Cmm2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 35,
    symbol: 'Bm2m',
    symbol_unicode: 'Cmm2',
    symbol_latex: 'Cmm2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Cmc2_1',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 36,
    symbol: 'Bm2_1b',
    symbol_unicode: 'Cmc2\u2081',
    symbol_latex: 'Cmc2$_{1}$',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Ccc2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 37,
    symbol: 'Bb2b',
    symbol_unicode: 'Ccc2',
    symbol_latex: 'Ccc2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Amm2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 38,
    symbol: 'Am2m',
    symbol_unicode: 'Amm2',
    symbol_latex: 'Amm2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Aem2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 39,
    symbol: 'Ae2m',
    symbol_unicode: 'Aem2',
    symbol_latex: 'Aem2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Ama2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 40,
    symbol: 'Am2a',
    symbol_unicode: 'Ama2',
    symbol_latex: 'Ama2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Aea2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 41,
    symbol: 'Ae2a',
    symbol_unicode: 'Aea2',
    symbol_latex: 'Aea2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Fmm2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 42,
    symbol: 'Fm2m',
    symbol_unicode: 'Fmm2',
    symbol_latex: 'Fmm2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Fdd2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 43,
    symbol: 'Fd2d',
    symbol_unicode: 'Fdd2',
    symbol_latex: 'Fdd2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Imm2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 44,
    symbol: 'Im2m',
    symbol_unicode: 'Imm2',
    symbol_latex: 'Imm2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Iba2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 45,
    symbol: 'Ic2a',
    symbol_unicode: 'Iba2',
    symbol_latex: 'Iba2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'Ima2',
    crystal_system: 'Orthorhombic',
    point_group: 'mm2',
    int_number: 46,
    symbol: 'Im2a',
    symbol_unicode: 'Ima2',
    symbol_latex: 'Ima2',
    point_group_unicode: 'mm2',
    point_group_latex: 'mm2'
  },
  {
    full_symbol: 'P2/m2/m2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 47,
    symbol: 'Pmmm',
    symbol_unicode: 'Pmmm',
    symbol_latex: 'Pmmm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/n2/n2/n',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 48,
    symbol: 'Pnnn',
    symbol_unicode: 'Pnnn1',
    symbol_latex: 'Pnnn1',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/c2/c2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 49,
    symbol: 'Pbmb',
    symbol_unicode: 'Pccm',
    symbol_latex: 'Pccm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/b2/a2/n',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 50,
    symbol: 'Pcna',
    symbol_unicode: 'Pban1',
    symbol_latex: 'Pban1',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/m2/m2/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 51,
    symbol: 'Pmam',
    symbol_unicode: 'Pmma',
    symbol_latex: 'Pmma',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/n2_1/n2/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 52,
    symbol: 'Pnan',
    symbol_unicode: 'Pnna',
    symbol_latex: 'Pnna',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/m2/n2_1/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 53,
    symbol: 'Pman',
    symbol_unicode: 'Pmna',
    symbol_latex: 'Pmna',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/c2/c2/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 54,
    symbol: 'Pbab',
    symbol_unicode: 'Pcca',
    symbol_latex: 'Pcca',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/b2_1/a2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 55,
    symbol: 'Pcma',
    symbol_unicode: 'Pbam',
    symbol_latex: 'Pbam',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/c2_1/c2/n',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 56,
    symbol: 'Pbnb',
    symbol_unicode: 'Pccn',
    symbol_latex: 'Pccn',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2/b2_1/c2_1/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 57,
    symbol: 'Pcmb',
    symbol_unicode: 'Pbcm',
    symbol_latex: 'Pbcm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/n2_1/n2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 58,
    symbol: 'Pnmn',
    symbol_unicode: 'Pnnm',
    symbol_latex: 'Pnnm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/m2_1/m2/n',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 59,
    symbol: 'Pmnm',
    symbol_unicode: 'Pmmn1',
    symbol_latex: 'Pmmn1',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/b2/c2_1/n',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 60,
    symbol: 'Pcnb',
    symbol_unicode: 'Pbcn',
    symbol_latex: 'Pbcn',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/b2_1/c2_1/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 61,
    symbol: 'Pcab',
    symbol_unicode: 'Pbca',
    symbol_latex: 'Pbca',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P2_1/n2_1/m2_1/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 62,
    symbol: 'Pnam',
    symbol_unicode: 'Pnma',
    symbol_latex: 'Pnma',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/m2/c2_1/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 63,
    symbol: 'Bmmb',
    symbol_unicode: 'Cmcm',
    symbol_latex: 'Cmcm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/m2/c2_1/e',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 64,
    symbol: 'Bmeb',
    symbol_unicode: 'Cmce',
    symbol_latex: 'Cmce',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/m2/m2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 65,
    symbol: 'Bmmm',
    symbol_unicode: 'Cmmm',
    symbol_latex: 'Cmmm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/c2/c2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 66,
    symbol: 'Bbmb',
    symbol_unicode: 'Cccm',
    symbol_latex: 'Cccm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/m2/m2/e',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 67,
    symbol: 'Bmem',
    symbol_unicode: 'Cmme',
    symbol_latex: 'Cmme',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'C2/c2/c2/e',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 68,
    symbol: 'Bbeb',
    symbol_unicode: 'Ccce1',
    symbol_latex: 'Ccce1',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'F2/m2/m2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 69,
    symbol: 'Fmmm',
    symbol_unicode: 'Fmmm',
    symbol_latex: 'Fmmm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'F2/d2/d2/d',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 70,
    symbol: 'Fddd',
    symbol_unicode: 'Fddd1',
    symbol_latex: 'Fddd1',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'I2/m2/m2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 71,
    symbol: 'Immm',
    symbol_unicode: 'Immm',
    symbol_latex: 'Immm',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'I2/b2/a2/m',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 72,
    symbol: 'Icma',
    symbol_unicode: 'Ibam',
    symbol_latex: 'Ibam',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'I2_1/b2_1/c2_1/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 73,
    symbol: 'Icab',
    symbol_unicode: 'Ibca',
    symbol_latex: 'Ibca',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'I2_1/m2_1/m2_1/a',
    crystal_system: 'Orthorhombic',
    point_group: 'mmm',
    int_number: 74,
    symbol: 'Imam',
    symbol_unicode: 'Imma',
    symbol_latex: 'Imma',
    point_group_unicode: 'mmm',
    point_group_latex: 'mmm'
  },
  {
    full_symbol: 'P4',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 75,
    symbol: 'P4',
    symbol_unicode: 'P4',
    symbol_latex: 'P4',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'P4_1',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 76,
    symbol: 'P4_1',
    symbol_unicode: 'P4\u2081',
    symbol_latex: 'P4$_{1}$',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'P4_2',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 77,
    symbol: 'P4_2',
    symbol_unicode: 'P4\u2082',
    symbol_latex: 'P4$_{2}$',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'P4_3',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 78,
    symbol: 'P4_3',
    symbol_unicode: 'P4\u2083',
    symbol_latex: 'P4$_{3}$',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'I4',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 79,
    symbol: 'I4',
    symbol_unicode: 'I4',
    symbol_latex: 'I4',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'I4_1',
    crystal_system: 'Tetragonal',
    point_group: '4',
    int_number: 80,
    symbol: 'I4_1',
    symbol_unicode: 'I4\u2081',
    symbol_latex: 'I4$_{1}$',
    point_group_unicode: '4',
    point_group_latex: '4'
  },
  {
    full_symbol: 'P-4',
    crystal_system: 'Tetragonal',
    point_group: '-4',
    int_number: 81,
    symbol: 'P-4',
    symbol_unicode: 'P4\u0305',
    symbol_latex: 'P$\\overline{4}$',
    point_group_unicode: '4\u0305',
    point_group_latex: '$\\overline{4}$'
  },
  {
    full_symbol: 'I-4',
    crystal_system: 'Tetragonal',
    point_group: '-4',
    int_number: 82,
    symbol: 'I-4',
    symbol_unicode: 'I4\u0305',
    symbol_latex: 'I$\\overline{4}$',
    point_group_unicode: '4\u0305',
    point_group_latex: '$\\overline{4}$'
  },
  {
    full_symbol: 'P4/m',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 83,
    symbol: 'P4/m',
    symbol_unicode: 'P4/m',
    symbol_latex: 'P4/m',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'P4_2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 84,
    symbol: 'P4_2/m',
    symbol_unicode: 'P4\u2082/m',
    symbol_latex: 'P4$_{2}$/m',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'P4/n',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 85,
    symbol: 'P4/n',
    symbol_unicode: 'P4/n1',
    symbol_latex: 'P4/n1',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'P4_2/n',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 86,
    symbol: 'P4_2/n',
    symbol_unicode: 'P4\u2082/n',
    symbol_latex: 'P4$_{2}$/n',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'I4/m',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 87,
    symbol: 'I4/m',
    symbol_unicode: 'I4/m',
    symbol_latex: 'I4/m',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'I4_1/a',
    crystal_system: 'Tetragonal',
    point_group: '4/m',
    int_number: 88,
    symbol: 'I4_1/a',
    symbol_unicode: 'I4\u2081/a',
    symbol_latex: 'I4$_{1}$/a',
    point_group_unicode: '4/m',
    point_group_latex: '4/m'
  },
  {
    full_symbol: 'P422',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 89,
    symbol: 'P422',
    symbol_unicode: 'P422',
    symbol_latex: 'P422',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P42_12',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 90,
    symbol: 'P42_12',
    symbol_unicode: 'P42_12\u0305',
    symbol_latex: 'P42$_{12}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_122',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 91,
    symbol: 'P4_122',
    symbol_unicode: 'P4_122\u0305',
    symbol_latex: 'P4$_{122}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_12_12',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 92,
    symbol: 'P4_12_12',
    symbol_unicode: 'P4_12\u0305_12\u0305',
    symbol_latex: 'P4$_{12}$$_{12}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_222',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 93,
    symbol: 'P4_222',
    symbol_unicode: 'P4_222\u0305',
    symbol_latex: 'P4$_{222}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_22_12',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 94,
    symbol: 'P4_22_12',
    symbol_unicode: 'P4_22\u0305_12\u0305',
    symbol_latex: 'P4$_{22}$$_{12}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_322',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 95,
    symbol: 'P4_322',
    symbol_unicode: 'P4_322\u0305',
    symbol_latex: 'P4$_{322}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4_32_12',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 96,
    symbol: 'P4_32_12',
    symbol_unicode: 'P4_32\u0305_12\u0305',
    symbol_latex: 'P4$_{32}$$_{12}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'I422',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 97,
    symbol: 'I422',
    symbol_unicode: 'I422',
    symbol_latex: 'I422',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'I4_122',
    crystal_system: 'Tetragonal',
    point_group: '422',
    int_number: 98,
    symbol: 'I4_122',
    symbol_unicode: 'I4_122\u0305',
    symbol_latex: 'I4$_{122}$',
    point_group_unicode: '422',
    point_group_latex: '422'
  },
  {
    full_symbol: 'P4mm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 99,
    symbol: 'P4mm',
    symbol_unicode: 'P4mm',
    symbol_latex: 'P4mm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4bm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 100,
    symbol: 'P4bm',
    symbol_unicode: 'P4bm',
    symbol_latex: 'P4bm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4_2cm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 101,
    symbol: 'P4_2cm',
    symbol_unicode: 'P4\u2082cm',
    symbol_latex: 'P4$_{2}$cm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4_2nm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 102,
    symbol: 'P4_2nm',
    symbol_unicode: 'P4\u2082nm',
    symbol_latex: 'P4$_{2}$nm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4cc',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 103,
    symbol: 'P4cc',
    symbol_unicode: 'P4cc',
    symbol_latex: 'P4cc',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4nc',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 104,
    symbol: 'P4nc',
    symbol_unicode: 'P4nc',
    symbol_latex: 'P4nc',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4_2mc',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 105,
    symbol: 'P4_2mc',
    symbol_unicode: 'P4\u2082mc',
    symbol_latex: 'P4$_{2}$mc',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P4_2bc',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 106,
    symbol: 'P4_2bc',
    symbol_unicode: 'P4\u2082bc',
    symbol_latex: 'P4$_{2}$bc',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'I4mm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 107,
    symbol: 'I4mm',
    symbol_unicode: 'I4mm',
    symbol_latex: 'I4mm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'I4cm',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 108,
    symbol: 'I4cm',
    symbol_unicode: 'I4cm',
    symbol_latex: 'I4cm',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'I4_1md',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 109,
    symbol: 'I4_1md',
    symbol_unicode: 'I4\u2081md',
    symbol_latex: 'I4$_{1}$md',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'I4_1cd',
    crystal_system: 'Tetragonal',
    point_group: '4mm',
    int_number: 110,
    symbol: 'I4_1cd',
    symbol_unicode: 'I4\u2081cd',
    symbol_latex: 'I4$_{1}$cd',
    point_group_unicode: '4mm',
    point_group_latex: '4mm'
  },
  {
    full_symbol: 'P-42m',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 111,
    symbol: 'P-42m',
    symbol_unicode: 'P4\u03052m',
    symbol_latex: 'P$\\overline{4}$2m',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'P-42c',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 112,
    symbol: 'P-42c',
    symbol_unicode: 'P4\u03052c',
    symbol_latex: 'P$\\overline{4}$2c',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'P-42_1m',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 113,
    symbol: 'P-42_1m',
    symbol_unicode: 'P4\u03052\u2081m',
    symbol_latex: 'P$\\overline{4}$2$_{1}$m',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'P-42_1c',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 114,
    symbol: 'P-42_1c',
    symbol_unicode: 'P4\u03052\u2081c',
    symbol_latex: 'P$\\overline{4}$2$_{1}$c',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'P-4m2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 115,
    symbol: 'P-4m2',
    symbol_unicode: 'P4\u0305m2',
    symbol_latex: 'P$\\overline{4}$m2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'P-4c2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 116,
    symbol: 'P-4c2',
    symbol_unicode: 'P4\u0305c2',
    symbol_latex: 'P$\\overline{4}$c2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'P-4b2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 117,
    symbol: 'P-4b2',
    symbol_unicode: 'P4\u0305b2',
    symbol_latex: 'P$\\overline{4}$b2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'P-4n2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 118,
    symbol: 'P-4n2',
    symbol_unicode: 'P4\u0305n2',
    symbol_latex: 'P$\\overline{4}$n2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'I-4m2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 119,
    symbol: 'I-4m2',
    symbol_unicode: 'I4\u0305m2',
    symbol_latex: 'I$\\overline{4}$m2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'I-4c2',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 120,
    symbol: 'I-4c2',
    symbol_unicode: 'I4\u0305c2',
    symbol_latex: 'I$\\overline{4}$c2',
    point_group_unicode: '4\u0305m2',
    point_group_latex: '$\\overline{4}$m2'
  },
  {
    full_symbol: 'I-42m',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 121,
    symbol: 'I-42m',
    symbol_unicode: 'I4\u03052m',
    symbol_latex: 'I$\\overline{4}$2m',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'I-42d',
    crystal_system: 'Tetragonal',
    point_group: '-42m',
    int_number: 122,
    symbol: 'I-42d',
    symbol_unicode: 'I4\u03052d',
    symbol_latex: 'I$\\overline{4}$2d',
    point_group_unicode: '4\u03052m',
    point_group_latex: '$\\overline{4}$2m'
  },
  {
    full_symbol: 'P4/m2/m2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 123,
    symbol: 'P4/mmm',
    symbol_unicode: 'P4/mmm',
    symbol_latex: 'P4/mmm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/m2/c2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 124,
    symbol: 'P4/mcc',
    symbol_unicode: 'P4/mcc',
    symbol_latex: 'P4/mcc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/n2/b2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 125,
    symbol: 'P4/nbm',
    symbol_unicode: 'P4/nbm1',
    symbol_latex: 'P4/nbm1',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/n2/n2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 126,
    symbol: 'P4/nnc',
    symbol_unicode: 'P4/nnc1',
    symbol_latex: 'P4/nnc1',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/m2_1/b2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 127,
    symbol: 'P4/mbm',
    symbol_unicode: 'P4/mbm',
    symbol_latex: 'P4/mbm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/m2_1/n2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 128,
    symbol: 'P4/mnc',
    symbol_unicode: 'P4/mnc',
    symbol_latex: 'P4/mnc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/n2_1/m2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 129,
    symbol: 'P4/nmm',
    symbol_unicode: 'P4/nmm1',
    symbol_latex: 'P4/nmm1',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4/n2_1/c2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 130,
    symbol: 'P4/ncc',
    symbol_unicode: 'P4/ncc1',
    symbol_latex: 'P4/ncc1',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/m2/m2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 131,
    symbol: 'P4_2/mmc',
    symbol_unicode: 'P4\u2082/mmc',
    symbol_latex: 'P4$_{2}$/mmc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/m2/c2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 132,
    symbol: 'P4_2/mcm',
    symbol_unicode: 'P4\u2082/mcm',
    symbol_latex: 'P4$_{2}$/mcm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/n2/b2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 133,
    symbol: 'P4_2/nbc',
    symbol_unicode: 'P4\u2082/nbc',
    symbol_latex: 'P4$_{2}$/nbc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/n2/n2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 134,
    symbol: 'P4_2/nnm',
    symbol_unicode: 'P4\u2082/nnm',
    symbol_latex: 'P4$_{2}$/nnm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/m2_1/b2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 135,
    symbol: 'P4_2/mbc',
    symbol_unicode: 'P4\u2082/mbc',
    symbol_latex: 'P4$_{2}$/mbc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/m2_1/n2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 136,
    symbol: 'P4_2/mnm',
    symbol_unicode: 'P4\u2082/mnm',
    symbol_latex: 'P4$_{2}$/mnm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/n2_1/m2/c',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 137,
    symbol: 'P4_2/nmc',
    symbol_unicode: 'P4\u2082/nmc',
    symbol_latex: 'P4$_{2}$/nmc',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P4_2/n2_1/c2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 138,
    symbol: 'P4_2/ncm',
    symbol_unicode: 'P4\u2082/ncm',
    symbol_latex: 'P4$_{2}$/ncm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'I4/m2/m2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 139,
    symbol: 'I4/mmm',
    symbol_unicode: 'I4/mmm',
    symbol_latex: 'I4/mmm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'I4/m2/c2/m',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 140,
    symbol: 'I4/mcm',
    symbol_unicode: 'I4/mcm',
    symbol_latex: 'I4/mcm',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'I4_1/a2/m2/d',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 141,
    symbol: 'I4_1/amd',
    symbol_unicode: 'I4\u2081/amd',
    symbol_latex: 'I4$_{1}$/amd',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'I4_1/a2/c2/d',
    crystal_system: 'Tetragonal',
    point_group: '4/mmm',
    int_number: 142,
    symbol: 'I4_1/acd',
    symbol_unicode: 'I4\u2081/acd',
    symbol_latex: 'I4$_{1}$/acd',
    point_group_unicode: '4/mmm',
    point_group_latex: '4/mmm'
  },
  {
    full_symbol: 'P3',
    crystal_system: 'Trigonal',
    point_group: '3',
    int_number: 143,
    symbol: 'P3',
    symbol_unicode: 'P3',
    symbol_latex: 'P3',
    point_group_unicode: '3',
    point_group_latex: '3'
  },
  {
    full_symbol: 'P3_1',
    crystal_system: 'Trigonal',
    point_group: '3',
    int_number: 144,
    symbol: 'P3_1',
    symbol_unicode: 'P3\u2081',
    symbol_latex: 'P3$_{1}$',
    point_group_unicode: '3',
    point_group_latex: '3'
  },
  {
    full_symbol: 'P3_2',
    crystal_system: 'Trigonal',
    point_group: '3',
    int_number: 145,
    symbol: 'P3_2',
    symbol_unicode: 'P3\u2082',
    symbol_latex: 'P3$_{2}$',
    point_group_unicode: '3',
    point_group_latex: '3'
  },
  {
    full_symbol: 'R3',
    crystal_system: 'Trigonal',
    point_group: '3',
    int_number: 146,
    symbol: 'R3',
    symbol_unicode: 'R3',
    symbol_latex: 'R3',
    point_group_unicode: '3',
    point_group_latex: '3'
  },
  {
    full_symbol: 'P-3',
    crystal_system: 'Trigonal',
    point_group: '-3',
    int_number: 147,
    symbol: 'P-3',
    symbol_unicode: 'P3\u0305',
    symbol_latex: 'P$\\overline{3}$',
    point_group_unicode: '3\u0305',
    point_group_latex: '$\\overline{3}$'
  },
  {
    full_symbol: 'R-3',
    crystal_system: 'Trigonal',
    point_group: '-3',
    int_number: 148,
    symbol: 'R-3',
    symbol_unicode: 'R3\u0305',
    symbol_latex: 'R$\\overline{3}$',
    point_group_unicode: '3\u0305',
    point_group_latex: '$\\overline{3}$'
  },
  {
    full_symbol: 'P312',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 149,
    symbol: 'P312',
    symbol_unicode: 'P312',
    symbol_latex: 'P312',
    point_group_unicode: '312',
    point_group_latex: '312'
  },
  {
    full_symbol: 'P321',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 150,
    symbol: 'P321',
    symbol_unicode: 'P321',
    symbol_latex: 'P321',
    point_group_unicode: '321',
    point_group_latex: '321'
  },
  {
    full_symbol: 'P3_112',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 151,
    symbol: 'P3_112',
    symbol_unicode: 'P3_112\u0305',
    symbol_latex: 'P3$_{112}$',
    point_group_unicode: '312',
    point_group_latex: '312'
  },
  {
    full_symbol: 'P3_121',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 152,
    symbol: 'P3_121',
    symbol_unicode: 'P3_121\u0305',
    symbol_latex: 'P3$_{121}$',
    point_group_unicode: '321',
    point_group_latex: '321'
  },
  {
    full_symbol: 'P3_212',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 153,
    symbol: 'P3_212',
    symbol_unicode: 'P3_212\u0305',
    symbol_latex: 'P3$_{212}$',
    point_group_unicode: '312',
    point_group_latex: '312'
  },
  {
    full_symbol: 'P3_221',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 154,
    symbol: 'P3_221',
    symbol_unicode: 'P3_221\u0305',
    symbol_latex: 'P3$_{221}$',
    point_group_unicode: '321',
    point_group_latex: '321'
  },
  {
    full_symbol: 'R32',
    crystal_system: 'Trigonal',
    point_group: '32',
    int_number: 155,
    symbol: 'R32',
    symbol_unicode: 'R32',
    symbol_latex: 'R32',
    point_group_unicode: '32',
    point_group_latex: '32'
  },
  {
    full_symbol: 'P3m1',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 156,
    symbol: 'P3m1',
    symbol_unicode: 'P3m1',
    symbol_latex: 'P3m1',
    point_group_unicode: '3m1',
    point_group_latex: '3m1'
  },
  {
    full_symbol: 'P31m',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 157,
    symbol: 'P31m',
    symbol_unicode: 'P31m',
    symbol_latex: 'P31m',
    point_group_unicode: '31m',
    point_group_latex: '31m'
  },
  {
    full_symbol: 'P3c1',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 158,
    symbol: 'P3c1',
    symbol_unicode: 'P3c1',
    symbol_latex: 'P3c1',
    point_group_unicode: '3m1',
    point_group_latex: '3m1'
  },
  {
    full_symbol: 'P31c',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 159,
    symbol: 'P31c',
    symbol_unicode: 'P31c',
    symbol_latex: 'P31c',
    point_group_unicode: '31m',
    point_group_latex: '31m'
  },
  {
    full_symbol: 'R3m',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 160,
    symbol: 'R3m',
    symbol_unicode: 'R3m',
    symbol_latex: 'R3m',
    point_group_unicode: '3m',
    point_group_latex: '3m'
  },
  {
    full_symbol: 'R3c',
    crystal_system: 'Trigonal',
    point_group: '3m',
    int_number: 161,
    symbol: 'R3c',
    symbol_unicode: 'R3c',
    symbol_latex: 'R3c',
    point_group_unicode: '3m',
    point_group_latex: '3m'
  },
  {
    full_symbol: 'P-312/m',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 162,
    symbol: 'P-31m',
    symbol_unicode: 'P3\u03051m',
    symbol_latex: 'P$\\overline{3}$1m',
    point_group_unicode: '3\u03051m',
    point_group_latex: '$\\overline{3}$1m'
  },
  {
    full_symbol: 'P-312/c',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 163,
    symbol: 'P-31c',
    symbol_unicode: 'P3\u03051c',
    symbol_latex: 'P$\\overline{3}$1c',
    point_group_unicode: '3\u03051m',
    point_group_latex: '$\\overline{3}$1m'
  },
  {
    full_symbol: 'P-32/m1',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 164,
    symbol: 'P-3m1',
    symbol_unicode: 'P3\u0305m1',
    symbol_latex: 'P$\\overline{3}$m1',
    point_group_unicode: '3\u0305m1',
    point_group_latex: '$\\overline{3}$m1'
  },
  {
    full_symbol: 'P-32/c1',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 165,
    symbol: 'P-3c1',
    symbol_unicode: 'P3\u0305c1',
    symbol_latex: 'P$\\overline{3}$c1',
    point_group_unicode: '3\u0305m1',
    point_group_latex: '$\\overline{3}$m1'
  },
  {
    full_symbol: 'R-32/m',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 166,
    symbol: 'R-3m',
    symbol_unicode: 'R3\u0305m',
    symbol_latex: 'R$\\overline{3}$m',
    point_group_unicode: '3\u0305m',
    point_group_latex: '$\\overline{3}$m'
  },
  {
    full_symbol: 'R-32/c',
    crystal_system: 'Trigonal',
    point_group: '-3m',
    int_number: 167,
    symbol: 'R-3c',
    symbol_unicode: 'R3\u0305c',
    symbol_latex: 'R$\\overline{3}$c',
    point_group_unicode: '3\u0305m',
    point_group_latex: '$\\overline{3}$m'
  },
  {
    full_symbol: 'P6',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 168,
    symbol: 'P6',
    symbol_unicode: 'P6',
    symbol_latex: 'P6',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P6_1',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 169,
    symbol: 'P6_1',
    symbol_unicode: 'P6\u2081',
    symbol_latex: 'P6$_{1}$',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P6_5',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 170,
    symbol: 'P6_5',
    symbol_unicode: 'P6\u2085',
    symbol_latex: 'P6$_{5}$',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P6_2',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 171,
    symbol: 'P6_2',
    symbol_unicode: 'P6\u2082',
    symbol_latex: 'P6$_{2}$',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P6_4',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 172,
    symbol: 'P6_4',
    symbol_unicode: 'P6\u2084',
    symbol_latex: 'P6$_{4}$',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P6_3',
    crystal_system: 'Hexagonal',
    point_group: '6',
    int_number: 173,
    symbol: 'P6_3',
    symbol_unicode: 'P6\u2083',
    symbol_latex: 'P6$_{3}$',
    point_group_unicode: '6',
    point_group_latex: '6'
  },
  {
    full_symbol: 'P-6',
    crystal_system: 'Hexagonal',
    point_group: '-6',
    int_number: 174,
    symbol: 'P-6',
    symbol_unicode: 'P6\u0305',
    symbol_latex: 'P$\\overline{6}$',
    point_group_unicode: '6\u0305',
    point_group_latex: '$\\overline{6}$'
  },
  {
    full_symbol: 'P6/m',
    crystal_system: 'Hexagonal',
    point_group: '6/m',
    int_number: 175,
    symbol: 'P6/m',
    symbol_unicode: 'P6/m',
    symbol_latex: 'P6/m',
    point_group_unicode: '6/m',
    point_group_latex: '6/m'
  },
  {
    full_symbol: 'P6_3/m',
    crystal_system: 'Hexagonal',
    point_group: '6/m',
    int_number: 176,
    symbol: 'P6_3/m',
    symbol_unicode: 'P6\u2083/m',
    symbol_latex: 'P6$_{3}$/m',
    point_group_unicode: '6/m',
    point_group_latex: '6/m'
  },
  {
    full_symbol: 'P622',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 177,
    symbol: 'P622',
    symbol_unicode: 'P622',
    symbol_latex: 'P622',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6_122',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 178,
    symbol: 'P6_122',
    symbol_unicode: 'P6_122\u0305',
    symbol_latex: 'P6$_{122}$',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6_522',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 179,
    symbol: 'P6_522',
    symbol_unicode: 'P6_522\u0305',
    symbol_latex: 'P6$_{522}$',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6_222',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 180,
    symbol: 'P6_222',
    symbol_unicode: 'P6_222\u0305',
    symbol_latex: 'P6$_{222}$',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6_422',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 181,
    symbol: 'P6_422',
    symbol_unicode: 'P6_422\u0305',
    symbol_latex: 'P6$_{422}$',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6_322',
    crystal_system: 'Hexagonal',
    point_group: '622',
    int_number: 182,
    symbol: 'P6_322',
    symbol_unicode: 'P6_322\u0305',
    symbol_latex: 'P6$_{322}$',
    point_group_unicode: '622',
    point_group_latex: '622'
  },
  {
    full_symbol: 'P6mm',
    crystal_system: 'Hexagonal',
    point_group: '6mm',
    int_number: 183,
    symbol: 'P6mm',
    symbol_unicode: 'P6mm',
    symbol_latex: 'P6mm',
    point_group_unicode: '6mm',
    point_group_latex: '6mm'
  },
  {
    full_symbol: 'P6cc',
    crystal_system: 'Hexagonal',
    point_group: '6mm',
    int_number: 184,
    symbol: 'P6cc',
    symbol_unicode: 'P6cc',
    symbol_latex: 'P6cc',
    point_group_unicode: '6mm',
    point_group_latex: '6mm'
  },
  {
    full_symbol: 'P6_3cm',
    crystal_system: 'Hexagonal',
    point_group: '6mm',
    int_number: 185,
    symbol: 'P6_3cm',
    symbol_unicode: 'P6\u2083cm',
    symbol_latex: 'P6$_{3}$cm',
    point_group_unicode: '6mm',
    point_group_latex: '6mm'
  },
  {
    full_symbol: 'P6_3mc',
    crystal_system: 'Hexagonal',
    point_group: '6mm',
    int_number: 186,
    symbol: 'P6_3mc',
    symbol_unicode: 'P6\u2083mc',
    symbol_latex: 'P6$_{3}$mc',
    point_group_unicode: '6mm',
    point_group_latex: '6mm'
  },
  {
    full_symbol: 'P-6m2',
    crystal_system: 'Hexagonal',
    point_group: '-6m2',
    int_number: 187,
    symbol: 'P-6m2',
    symbol_unicode: 'P6\u0305m2',
    symbol_latex: 'P$\\overline{6}$m2',
    point_group_unicode: '6\u0305m2',
    point_group_latex: '$\\overline{6}$m2'
  },
  {
    full_symbol: 'P-6c2',
    crystal_system: 'Hexagonal',
    point_group: '-6m2',
    int_number: 188,
    symbol: 'P-6c2',
    symbol_unicode: 'P6\u0305c2',
    symbol_latex: 'P$\\overline{6}$c2',
    point_group_unicode: '6\u0305m2',
    point_group_latex: '$\\overline{6}$m2'
  },
  {
    full_symbol: 'P-62m',
    crystal_system: 'Hexagonal',
    point_group: '-6m2',
    int_number: 189,
    symbol: 'P-62m',
    symbol_unicode: 'P6\u03052m',
    symbol_latex: 'P$\\overline{6}$2m',
    point_group_unicode: '6\u03052m',
    point_group_latex: '$\\overline{6}$2m'
  },
  {
    full_symbol: 'P-62c',
    crystal_system: 'Hexagonal',
    point_group: '-6m2',
    int_number: 190,
    symbol: 'P-62c',
    symbol_unicode: 'P6\u03052c',
    symbol_latex: 'P$\\overline{6}$2c',
    point_group_unicode: '6\u03052m',
    point_group_latex: '$\\overline{6}$2m'
  },
  {
    full_symbol: 'P6/m2/m2/m',
    crystal_system: 'Hexagonal',
    point_group: '6/mmm',
    int_number: 191,
    symbol: 'P6/mmm',
    symbol_unicode: 'P6/mmm',
    symbol_latex: 'P6/mmm',
    point_group_unicode: '6/mmm',
    point_group_latex: '6/mmm'
  },
  {
    full_symbol: 'P6/m2/c2/c',
    crystal_system: 'Hexagonal',
    point_group: '6/mmm',
    int_number: 192,
    symbol: 'P6/mcc',
    symbol_unicode: 'P6/mcc',
    symbol_latex: 'P6/mcc',
    point_group_unicode: '6/mmm',
    point_group_latex: '6/mmm'
  },
  {
    full_symbol: 'P6_3/m2/c2/m',
    crystal_system: 'Hexagonal',
    point_group: '6/mmm',
    int_number: 193,
    symbol: 'P6_3/mcm',
    symbol_unicode: 'P6\u2083/mcm',
    symbol_latex: 'P6$_{3}$/mcm',
    point_group_unicode: '6/mmm',
    point_group_latex: '6/mmm'
  },
  {
    full_symbol: 'P6_3/m2/m2/c',
    crystal_system: 'Hexagonal',
    point_group: '6/mmm',
    int_number: 194,
    symbol: 'P6_3/mmc',
    symbol_unicode: 'P6\u2083/mmc',
    symbol_latex: 'P6$_{3}$/mmc',
    point_group_unicode: '6/mmm',
    point_group_latex: '6/mmm'
  },
  {
    full_symbol: 'P23',
    crystal_system: 'Cubic',
    point_group: '23',
    int_number: 195,
    symbol: 'P23',
    symbol_unicode: 'P23',
    symbol_latex: 'P23',
    point_group_unicode: '23',
    point_group_latex: '23'
  },
  {
    full_symbol: 'F23',
    crystal_system: 'Cubic',
    point_group: '23',
    int_number: 196,
    symbol: 'F23',
    symbol_unicode: 'F23',
    symbol_latex: 'F23',
    point_group_unicode: '23',
    point_group_latex: '23'
  },
  {
    full_symbol: 'I23',
    crystal_system: 'Cubic',
    point_group: '23',
    int_number: 197,
    symbol: 'I23',
    symbol_unicode: 'I23',
    symbol_latex: 'I23',
    point_group_unicode: '23',
    point_group_latex: '23'
  },
  {
    full_symbol: 'P2_13',
    crystal_system: 'Cubic',
    point_group: '23',
    int_number: 198,
    symbol: 'P2_13',
    symbol_unicode: 'P2_13\u0305',
    symbol_latex: 'P2$_{13}$',
    point_group_unicode: '23',
    point_group_latex: '23'
  },
  {
    full_symbol: 'I2_13',
    crystal_system: 'Cubic',
    point_group: '23',
    int_number: 199,
    symbol: 'I2_13',
    symbol_unicode: 'I2_13\u0305',
    symbol_latex: 'I2$_{13}$',
    point_group_unicode: '23',
    point_group_latex: '23'
  },
  {
    full_symbol: 'P2/m-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 200,
    symbol: 'Pm-3',
    symbol_unicode: 'Pm3\u0305',
    symbol_latex: 'Pm$\\overline{3}$',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'P2/n-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 201,
    symbol: 'Pn-3',
    symbol_unicode: 'Pn3\u03051',
    symbol_latex: 'Pn$\\overline{3}$1',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'F2/m-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 202,
    symbol: 'Fm-3',
    symbol_unicode: 'Fm3\u0305',
    symbol_latex: 'Fm$\\overline{3}$',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'F2/d-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 203,
    symbol: 'Fd-3',
    symbol_unicode: 'Fd3\u03051',
    symbol_latex: 'Fd$\\overline{3}$1',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'I2/m-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 204,
    symbol: 'Im-3',
    symbol_unicode: 'Im3\u0305',
    symbol_latex: 'Im$\\overline{3}$',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'P2_1/a-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 205,
    symbol: 'Pa-3',
    symbol_unicode: 'Pa3\u0305',
    symbol_latex: 'Pa$\\overline{3}$',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'I2_1/a-3',
    crystal_system: 'Cubic',
    point_group: 'm-3',
    int_number: 206,
    symbol: 'Ia-3',
    symbol_unicode: 'Ia3\u0305',
    symbol_latex: 'Ia$\\overline{3}$',
    point_group_unicode: 'm3\u0305',
    point_group_latex: 'm$\\overline{3}$'
  },
  {
    full_symbol: 'P432',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 207,
    symbol: 'P432',
    symbol_unicode: 'P432',
    symbol_latex: 'P432',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'P4_232',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 208,
    symbol: 'P4_232',
    symbol_unicode: 'P4_232\u0305',
    symbol_latex: 'P4$_{232}$',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'F432',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 209,
    symbol: 'F432',
    symbol_unicode: 'F432',
    symbol_latex: 'F432',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'F4_132',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 210,
    symbol: 'F4_132',
    symbol_unicode: 'F4_132\u0305',
    symbol_latex: 'F4$_{132}$',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'I432',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 211,
    symbol: 'I432',
    symbol_unicode: 'I432',
    symbol_latex: 'I432',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'P4_332',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 212,
    symbol: 'P4_332',
    symbol_unicode: 'P4_332\u0305',
    symbol_latex: 'P4$_{332}$',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'P4_132',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 213,
    symbol: 'P4_132',
    symbol_unicode: 'P4_132\u0305',
    symbol_latex: 'P4$_{132}$',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'I4_132',
    crystal_system: 'Cubic',
    point_group: '432',
    int_number: 214,
    symbol: 'I4_132',
    symbol_unicode: 'I4_132\u0305',
    symbol_latex: 'I4$_{132}$',
    point_group_unicode: '432',
    point_group_latex: '432'
  },
  {
    full_symbol: 'P-43m',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 215,
    symbol: 'P-43m',
    symbol_unicode: 'P4\u03053m',
    symbol_latex: 'P$\\overline{4}$3m',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'F-43m',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 216,
    symbol: 'F-43m',
    symbol_unicode: 'F4\u03053m',
    symbol_latex: 'F$\\overline{4}$3m',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'I-43m',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 217,
    symbol: 'I-43m',
    symbol_unicode: 'I4\u03053m',
    symbol_latex: 'I$\\overline{4}$3m',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'P-43n',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 218,
    symbol: 'P-43n',
    symbol_unicode: 'P4\u03053n',
    symbol_latex: 'P$\\overline{4}$3n',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'F-43c',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 219,
    symbol: 'F-43c',
    symbol_unicode: 'F4\u03053c',
    symbol_latex: 'F$\\overline{4}$3c',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'I-43d',
    crystal_system: 'Cubic',
    point_group: '-43m',
    int_number: 220,
    symbol: 'I-43d',
    symbol_unicode: 'I4\u03053d',
    symbol_latex: 'I$\\overline{4}$3d',
    point_group_unicode: '4\u03053m',
    point_group_latex: '$\\overline{4}$3m'
  },
  {
    full_symbol: 'P4/m-32/m',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 221,
    symbol: 'Pm-3m',
    symbol_unicode: 'Pm3\u0305m',
    symbol_latex: 'Pm$\\overline{3}$m',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'P4/n-32/n',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 222,
    symbol: 'Pn-3n',
    symbol_unicode: 'Pn3\u0305n1',
    symbol_latex: 'Pn$\\overline{3}$n1',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'P4_2/m-32/n',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 223,
    symbol: 'Pm-3n',
    symbol_unicode: 'Pm3\u0305n',
    symbol_latex: 'Pm$\\overline{3}$n',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'P4_2/n-32/m',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 224,
    symbol: 'Pn-3m',
    symbol_unicode: 'Pn3\u0305m1',
    symbol_latex: 'Pn$\\overline{3}$m1',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'F4/m-32/m',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 225,
    symbol: 'Fm-3m',
    symbol_unicode: 'Fm3\u0305m',
    symbol_latex: 'Fm$\\overline{3}$m',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'F4/m-32/c',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 226,
    symbol: 'Fm-3c',
    symbol_unicode: 'Fm3\u0305c',
    symbol_latex: 'Fm$\\overline{3}$c',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'F4_1/d-32/m',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 227,
    symbol: 'Fd-3m',
    symbol_unicode: 'Fd3\u0305m1',
    symbol_latex: 'Fd$\\overline{3}$m1',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'F4_1/d-32/c',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 228,
    symbol: 'Fd-3c',
    symbol_unicode: 'Fd3\u0305c1',
    symbol_latex: 'Fd$\\overline{3}$c1',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'I4/m-32/m',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 229,
    symbol: 'Im-3m',
    symbol_unicode: 'Im3\u0305m',
    symbol_latex: 'Im$\\overline{3}$m',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  },
  {
    full_symbol: 'I4_1/a-32/d',
    crystal_system: 'Cubic',
    point_group: 'm-3m',
    int_number: 230,
    symbol: 'Ia-3d',
    symbol_unicode: 'Ia3\u0305d',
    symbol_latex: 'Ia$\\overline{3}$d',
    point_group_unicode: 'm3\u0305m',
    point_group_latex: 'm$\\overline{3}$m'
  }
];
