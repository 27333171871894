import React, { useCallback, useEffect, useState } from 'react';
import { CameraContextProvider } from '../../components/crystal-toolkit/CameraContextProvider';
import { AnimationStyle, Renderer } from '../../components/crystal-toolkit/scene/constants';
import { CrystalToolkitScene } from '../../components/crystal-toolkit/CrystalToolkitScene/CrystalToolkitScene';
import { ExportType } from '../../components/crystal-toolkit/scene/constants';
import { Download } from '../../components/crystal-toolkit/Download';
import { ScenePosition } from '../../components/crystal-toolkit/scene/inset-helper';
import { CameraState } from '../../components/crystal-toolkit/CameraContextProvider/camera-reducer';
import * as THREE from 'three';
import { Camera } from 'three';
import { useRef } from 'react';
import { Enlargeable } from '../../components/data-display/Enlargeable';
import axios from 'axios';
import qs from 'qs';
import { useQueryParams } from 'use-query-params';
import { useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { documentTitle } from '../../app';

/**
 * Component for testing Scene
 */
interface Structure {
  possible_species: String[];
  energy_above_hull: String;
  formation_energy_per_atom: String;
  symmetry: {
    symbol: String;
  };
  ordering: String;
  theoretical: Boolean;
  band_gap: String;
  total_magnetization: String;
  formula_pretty: String;
  material_id: String;
}

interface Legend {
  name: String;
  color: String;
}
const vis = { atoms: true };

export const CrystalStructureViewer: React.FC = () => {
  const [structureContainerWidth, setStructureContainerWidth] = useState('40%');
  const structureContainer = useRef(null);
  const [sceneData, setSceneData] = useState({});
  const [dataInput, setDataInput] = useState<any>();
  const [structureData, setStructureData] = useState<Structure>({
    possible_species: [],
    energy_above_hull: '',
    band_gap: '',
    ordering: '',
    formation_energy_per_atom: '',
    theoretical: false,
    total_magnetization: '',
    symmetry: {
      symbol: ''
    },
    formula_pretty: '',
    material_id: ''
  });

  const [legends, setLegends] = useState<Legend[]>([]);
  const cameraState2 = {};
  const download_options = {
    Structure: {
      CIF: { fmt: 'cif' },
      POSCAR: { fmt: 'poscar' },
      JSON: { fmt: 'json' },
      Prismatic: { fmt: 'prismatic' }
    }
  };

  const [state, setState] = useState<any>({
    imageData: undefined,
    imageRequest: undefined,
    imageDataTimestamp: undefined,
    currentCameraState: undefined,
    customCameraState: cameraState2,
    fileType: '',
    fileTimestamp: ''
  });

  const onChangeScene = useCallback(async (nextState) => {
    const { fileType } = nextState;
    if (fileType !== undefined) {
      if (fileType != state.fileType) {
        window.location.href =
          process.env.REACT_APP_BASE_URL +
          '/materials/file?material_id=' +
          material_id.slice(3) +
          '&fmt=' +
          download_options['Structure'][fileType]['fmt'];
      }
    }
    setState(nextState);
  }, []);

  const [value, setValue] = useState<any>('test');

  const { material_id } = useParams();

  useEffect(() => {
    document.title = documentTitle;
    if (state.imageData) {
      setDataInput({
        filename: `${structureData.formula_pretty}`,
        content: state.imageData,
        mimeType: 'image/png',
        isDataURL: true
      });
    }
  }, [state.imageDataTimestamp]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_BASE_URL + '/materials/?material_id=' + material_id.slice(3)
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        setSceneData(jsonData['sceneData']);
        setStructureData(jsonData['structureData']);
        let _legends = [...legends];
        _legends = Object.keys(jsonData['legend']).map((key) => {
          return {
            name: jsonData['legend'][key],
            color: key
          };
        });
        setLegends(_legends);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this runs only once

  return (
    <div>
      <nav className="breadcrumb">
        <ul>
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href={`/?chemsys=${structureData.chemsys}`}>{structureData.chemsys}</a>
          </li>
          <li>
            <a href={`/?formula=${structureData.formula_pretty}`}>{structureData.formula_pretty}</a>
          </li>
          <li className="is-active">
            <a href={`/materials/${structureData.material_id}?chemsys=${structureData.chemsys}`}>
              {structureData.material_id}
            </a>
          </li>
        </ul>
      </nav>
      <br></br>
      <CameraContextProvider>
        <>
          <div style={{ display: 'flex' }}>
            <div ref={structureContainer} style={{ width: structureContainerWidth }}>
              <CrystalToolkitScene
                className="box"
                setProps={onChangeScene}
                settings={{
                  renderer: Renderer.WEBGL,
                  draw_image_atoms: true,
                  bonded_sites_outside_unit_cell: true,
                  extractAxis: true
                }}
                data={sceneData}
                id="3d"
                sceneSize="100%"
                debug={false}
                toggleVisibility={{}}
                imageData={state.imageData}
                imageDataTimestamp={state.imageDataTimestamp}
                fileOptions={['CIF', 'POSCAR', 'JSON', 'Prismatic']}
                fileType={state.fileType}
                fileTimestamp={state.fileTimestamp}
                showControls={true}
                showExpandButton={true}
                showExportButton={true}
                showImageButton={true}
                showPositionButton={true}
                children={[
                  <div>settings</div>,
                  <div
                    style={{
                      display: 'flex'
                    }}
                  >
                    {legends.map((item) => {
                      return (
                        <span
                          className="button is-rounded"
                          style={{
                            backgroundColor: item.color
                          }}
                        >
                          {item.name}
                        </span>
                      );
                    })}
                  </div>
                ]}
              ></CrystalToolkitScene>
            </div>
            <div className="box">
              <table className="table">
                <tbody>
                  <tr>
                    <td>
                      <label className="label">Formula</label>
                    </td>
                    <td>
                      <a href="#thermodynamic_stability">{structureData.formula_pretty}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Space Group Symbol</label>
                    </td>
                    <td>
                      <a href="#crystal_structure">{structureData.symmetry.symbol}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Band Gap</label>
                    </td>
                    <td>
                      <a href="#electronic_Structure">
                        <NumberFormat
                          value={structureData.band_gap}
                          displayType="text" // Display as plain text
                          thousandSeparator // Add a comma as a thousand separator
                          decimalScale={3} // Specify the number of decimal places
                          fixedDecimalScale // Ensure fixed decimal places (no rounding)
                        />{' '}
                        eV
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">CBM</label>
                    </td>
                    <td>
                      <a href="#thermodynamic_stability">
                        <NumberFormat
                          value={structureData.cbm}
                          displayType="text" // Display as plain text
                          thousandSeparator // Add a comma as a thousand separator
                          decimalScale={3} // Specify the number of decimal places
                          fixedDecimalScale // Ensure fixed decimal places (no rounding)
                        />{' '}
                        eV
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">VBM</label>
                    </td>
                    <td>
                      <a href="#magnetic_properties">
                        <NumberFormat
                          value={structureData.vbm}
                          displayType="text" // Display as plain text
                          thousandSeparator // Add a comma as a thousand separator
                          decimalScale={2} // Specify the number of decimal places
                          fixedDecimalScale // Ensure fixed decimal places (no rounding)
                        />{' '}
                        eV
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="label">Formation Energy</label>
                    </td>
                    <td>
                      <a href="#magnetic_properties">
                        <NumberFormat
                          value={structureData.formation_energy_per_atom}
                          displayType="text" // Display as plain text
                          thousandSeparator // Add a comma as a thousand separator
                          decimalScale={3} // Specify the number of decimal places
                          fixedDecimalScale // Ensure fixed decimal places (no rounding)
                        />{' '}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      </CameraContextProvider>
      <p>{state.fileType}</p>
      <p>{state.fileTimestamp}</p>
      <p>{state.imageDataTimestamp}</p>
      <Download id="image-download" data={dataInput} />
    </div>
  );
};
